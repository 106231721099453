@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;
.ck.ck-content.ck-editor__editable {
  min-height: 300px;
  padding: 0 20px;
}

.ck.ck-content.ck-editor__editable h1 {
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
}

.ck.ck-content.ck-editor__editable h2 {
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
}

.ck.ck-content.ck-editor__editable h3 {
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
}

.ck.ck-content.ck-editor__editable h4 {
  font-size: 1em;
}

.ck.ck-content.ck-editor__editable h5 {
  font-size: 0.83em;
}

.ck.ck-content.ck-editor__editable h6 {
  font-size: 0.67em;
}
body {
  overflow: hidden;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family:
    'Nunito',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
}
